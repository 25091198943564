.page--container {
    min-height: 100vh;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    opacity: 0.9;
}

.title {
    padding: 20px;
    text-align: center;
    font-weight: 100 !important;
    font-size: var(--titleSize1);
}

.wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    opacity: 0.1;
}
