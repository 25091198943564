.description {
    padding-bottom: 70px;
}

.card-group {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.card {
    max-width: 200px;
    transition-duration: 0.2s;
    background: var(--bgColor2);
    border: 0;
    box-shadow: 0 0 10px 0px var(--txtColor3);
}
.card:hover {
    box-shadow: 0 0 10px 0px var(--txtColor1);
}

.card--image{
    margin: 5px;
    height: auto;
    width: auto;
    max-width: 200px;
}