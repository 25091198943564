.navbar {
    position: fixed;
    opacity: 0.9;
    background-color: var(--bgColor1);
    z-index: 10;
    transition: var(--speed1) ease;
}

.navbar-brand {
    font-weight: 10;
    /* height: 100%; */
    color: var(--txtColor1) !important;
}

.navbar-collapse {
    justify-content: flex-end;
}

.navbar-nav {
    align-items: center;
    opacity: 0.6;
}

.very-small{
    font-size: 15px;
    letter-spacing: -0.03em;
    opacity: 0.7;
}

.nav-link{
    color: var(--txtColor2) !important;
}

.mode-switcher {
    width: 150px;
    padding: 20px;
    color: var(--txtColor1);
}

.mode-bg {
    width: 30px;
    height: 20px;
    background-color: var(--txtColor1);
    border-radius: 10px;
    display: flex;
    padding: 1px;
    align-items: center;
}
.mode-button {
    width: 18px;
    height: 18px;
    background-color: var(--bgColor1);
    border-radius: 10px;
    padding: 0;
}

.mode-bg:hover {
    cursor: pointer;
}