

.text-input {
    text-shadow: none;
    background-color: var(--bgColor1) !important;
    color: var(--txtColor1) !important;
}




.info--container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.info {
    padding: 20px 0px;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}