.canvas--items {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 500;
}

.counter {
  text-align: center;
  opacity: 0.7;
}

.canvas--panel {
  display: flex;
  justify-content: center;
}

.btn {
  width: 70px;
  margin: 5px;
  font-size: 14px;
  font-weight: 100;
  height: 35px;
}
.button--half {
  max-width: 35px;
  height: 35px;
  padding: 0;
}
