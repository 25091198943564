.footer--link:hover {
    text-decoration: none;
}

.footer {
    height: 10em;
    margin-top: 5em;
    background-color: var(--bgColor1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    transition: 
    background-color var(--speed1) ease,
    color var(--speed1) ease,;
}

.footer > *{
    color: var(--txtColor1) !important;
}


.footer--info {
    width: 70%;
    justify-content: space-between;
}



.footer--copyright {
    width: 75%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}