.subtitle {
  font-weight: 100;
  text-align: center;
  font-size: var(--titleSize2);
}

.subtitle--container {
  display: flex;
  justify-content: center;
}
