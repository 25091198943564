.jumbotron {
  position: relative;
  min-height: 100vh;
  width: 100%;
  padding: 0;
  margin: 0;
  background: none;
  z-index: -1;
}

.jumbotron--container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
}

.jumbotron--title--container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* text-align: center; */
  font-weight: 100;
  font-size: 30px;
  z-index: 100;
}

.jumbotron--title--container > h1 {
  text-align: center;
  font-weight: 100 !important;
  font-size: 80px !important;
}

.jumbotron--title--container > p {
  text-align: center;
  font-weight: 100 !important;
  font-size: 20px !important;
}
