.btn {
  width: 100px;
}

.preview--container {
  width: 300px;
  height: 300px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.9;
  background-color: var(--bgColor1);
}

.section-title {
  font-weight: 100;
  font-size: xx-large;
}

.section-paragraph {
  font-weight: 100;
}

.ref-url:hover {
  text-decoration: none;
}

.ref-btn {
  width: 150px;
}
