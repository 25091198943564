.filter {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  --bur: 5px;
  -webkit-filter: blur(var(--bur));
  -moz-filter: blur(var(--bur));
  -o-filter: blur(var(--bur));
  -ms-filter: blur(var(--bur));
  filter: blur(var(--bur));
  z-index: 0;
}

.cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
