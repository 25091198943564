.sandbox--container {
  /* position: relative; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  z-index: 1000;
}
.sandbox {
  position: relative;
  min-width: 300px;
  min-height: 300px;
  max-height: 100px;
  /* overflow: hidden; */
}
